'use client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCookies } from 'next-client-cookies';
import { CookieStore, PartnerUpdateReferralLinkClientDTO } from '@exchanger/common';
import dynamic from 'next/dynamic'
import { PartnerService } from '@/service/partner'
import { useMutation } from '@tanstack/react-query'
import Iframe from 'react-iframe'

const WhyChooseUs = dynamic(() => import('./WhyChooseUs'))
const Stats = dynamic(() => import('./Stats'))
const Faq = dynamic(() => import('./Faq'))
const CustomerReviews = dynamic(() => import('./CustomerReviews'))
const AffiliateReferralProgram = dynamic(() => import('./AffiliateReferralProgram'))
const Partner = dynamic(() => import('./Partner'))

const Welcome = () => {
  const { t, i18n } = useTranslation();
  const searchParams = useSearchParams();
  const cookieStore = useCookies();
  const router = useRouter(); 
  const [orderId, setOrderId] = useState<string | null>(null);
  const [ref, setRef] = useState<string | null>(null);

  const { mutate } = useMutation({
    mutationKey: ['partner-update-ref-link'],
    mutationFn: (data: PartnerUpdateReferralLinkClientDTO) => PartnerService.partnerUpdateRefLink(data),
  });


  useEffect(() => {
    const ref = searchParams.get('ref');
    if (ref) {
      cookieStore.set(CookieStore.referralCode, ref, {
        expires: 60 * 60 * 24 * 365,
      });
      setRef(ref);
      mutate({
        referralCode: ref,
        path: window.location.search
      })
    }
    const orderId = searchParams.get('orderId');
    if (orderId) {
      setOrderId(orderId);
    }
  }, [searchParams]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== 'https://widget.ebitok.com') return;

      if (event.data.type === 'navigate') {
        if (event.data.url === 'term_of_use') {
          window.open('/docs/term-of-use', '_blank');
        }
        if (event.data.url === 'privacy_policy') {
          window.open('/docs/privacy-policy', '_blank');
        }
        if (event.data.url === 'aml_kyc') {
          window.open('/docs/aml-kyc', '_blank');
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <>
      <div className='relative flex flex-col items-center  gap-0 sm:gap-6 md:gap-9 md:p-0 sm:p-0 '>
        <h1 className='text-white text-[28px] md:text-[42px] text-center p-4 font-semibold m-0 mt-6'>
          {t('title.h')} <b className='text-gradient-default font-extrabold'>{t('title.gradient')}</b> <b>{t('title.b')}</b>
        </h1>
        <h2 className='text-white text-sm md:text-lg leading-6 font-medium p-4 text-center m-0 whitespace-pre-line'>
          {t('subtitle')}
        </h2>
        <div className="flex flex-col items-center justify-center w-full">
        <Iframe url={`https://widget.ebitok.com/widget?orderId=${orderId}&ref=${ref}&locale=${i18n.language}`}
        height="630px"
        id="widget"
        className="w-full"
        display="block"
        position="relative"
        allowFullScreen
        allow='clipboard-write; clipboard-read; camera; picture-in-picture'
        />
        </div>
        
      </div>
      <Partner />
      <WhyChooseUs />
      <AffiliateReferralProgram />
      <Stats />
      <CustomerReviews />
      <Faq />
    </>
  );
};

export default Welcome;
